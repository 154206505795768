import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Price from '../components/homepage/sectionPrice'


const Services = () => (
	<Layout>
		<Seo title='Услуги и цены web-студии EnotWeb' />
		<Price
			title='Услуги и цены'
			description='Мы подобрали оптимальные решения для быстрого старта твоего проекта в Интернет.'
		/>
	</Layout>
)

export default Services