import React from 'react'
import { Link } from 'gatsby'

/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'

const priceRecources = [
	{
		title: 'Сайт на WordPress',
		description: 'Сайт создается на базе готового премиум шаблона. Всё для сайта - шаблон, плагин(ы), домен(ы), хостинг - покупаются и регистрируются на твое имя. Мы все это соберем в готовый сайт, а тебе останется только наполнить его контентом.',
		features: [
			'WordPress',
			'Премиум шаблон и плагин от wpshop.ru',
			'Хостинг на 1 год + SSL сертификат',
			'Домен в зоне .RU',
		],
		priceslogan: '',
		price: '10\'700',
		currency: 'руб',
		buttontext: 'Хочу такой сайт',
    buttonlink: '/website-on-wordpress',
		bottomlink: '',
		bottomlinktext: '',
		bottomlinkspan: '',
	},
	{
		title: 'Сайт на конструкторе',
		description: 'Здесь ты все делаешь самостоятельно и экономишь деньги на разработке сайта. Конструктор на платформе WordPress, что дает неограниченные возможности по развитию проекта. Выбирай подходящий тебе шаблон и получи готовый сайт.',
		features: [
			'Визуальный конструктор на базе WordPress',
			'200 шаблонов под любой бизнес или проект',
			'40+ настраиваемых модулей',
			'SSL сертификат',
		],
		priceslogan: 'абонентка / месяц',
		price: 'от 290',
		currency: 'руб',
		buttontext: 'Хочу конструировать',
    buttonlink: '/websitebuilder',
		bottomlinktext: 'Попробуй всего за 1 руб',
    bottomlink: '//www.reg.ru/web-sites/regsite/?rlink=reflink-6822007',
		bottomlinkspan: '',
	},
  {
		title: 'Комплексный сайт для бизнеса',
		description: 'Компания REG.ru запускает готовый сайт заточенный под твой бизнес за 1 день. Есть продуманные сайты для нескольких отраслей бизнеса: Интернет-магазины, Медицина, Обучение, Кафе и рестораны, Отели, Бьюти',
		features: [
			'Продуманный обновляемый шаблон',
			'Все инструменты без дополнительных доплат',
			'Быстрое внесение любых изменений',
			'Персональный менеджер',
			'Интеграция оплаты на сайте',
			'Поддержка 24/7',
			'SSL-сертификат',
			'Защита от DDoS',
		],
		priceslogan: 'абонентка в месяц',
		price: 'от 750',
		currency: 'руб',
		buttontext: 'Хочу такой сайт',
    buttonlink: '//solutions.reg.ru/?rlink=reflink-6822007',
		bottomlink: '',
		bottomlinktext: '',
		bottomlinkspan: '',
	},
	{
		title: 'Сопровождение сайта',
		description: 'Чтобы сайт работал без перебоев, был защищен, а контент не потерялся надо немного регулярной технической заботы. Мы берем эту рутину на себя. Оплата за месяц вперед. Отказаться от услуги возможно в любой момент.',
		features: [
			'Резервное копирование 1 раз в месяц',
			'Обновление движка и плагинов 1 раз в месяц',
			'Мониторинг доступности сайта',
		],
		priceslogan: 'абонентка / месяц',
		price: '1\'500',
		currency: 'руб',
		buttontext: 'Хочу сопровождение',
    buttonlink: '/maintenance',
		bottomlink: '',
		bottomlinktext: '',
		bottomlinkspan: '',
	},
	// {
	// 	title: 'Консультации',
	// 	description: 'Если есть вопросы по работе с сайтом, настройкам, да вообще о чем угодно, то мы готовы поделиться с тобой своим опытом и знаниями. Минимальная сессия 30 минут. Оплачивается вперед. Проходит в формате простого диалога через любой адекватный мессенджер.',
	// 	features: [
	// 		'Сессия от 30 минут',
	// 		'Время назначаем заранее',
	// 		'Демонстрация экрана, если нужно',
	// 		'Позитивный настрой :)',
	// 	],
	// 	priceslogan: 'сессия 30 минут',
	// 	price: '1\'500',
	// 	currency: 'руб',
	// 	buttontext: 'Хочу консультацию',
  //   buttonlink: '/consultation',
	// 	bottomlink: '',
	// 	bottomlinktext: '',
	// 	bottomlinkspan: '',
	// },
]

export default function SectionPrice({
  title='Заголовок',
  description='Описание' }) {
  return (
    <div className="bg-white pb-20">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">{title}</h2>
            <p className="mt-4 text-xl text-gray-600">
              {description}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28"> */}

			{priceRecources.map((item) => (

      <div key={item.title} className="mt-8 bg-white pb-2 sm:mt-12 sm:pb-2 lg:pb-2">
        <div className="relative">
          {/* <div className="absolute inset-0 h-1/2 bg-gray-100" /> */}
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg border border-slate-200 overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">{item.title}</h3>
                <p className="mt-6 text-base text-gray-500">
									{item.description}
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                      Что включено
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul role="list" className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {item.features.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                <p className="text-lg leading-6 font-medium text-gray-900">{item.priceslogan}</p>
                <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                  <span>{item.price}</span>
                  <span className="ml-3 text-xl font-medium text-gray-500">{item.currency}</span>
                </div>
                {/* <p className="mt-4 text-sm">
                  <a href="#" className="font-medium text-gray-500 underline">
                    Learn about our membership policy
                  </a>
                </p> */}
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <Link
                      to={item.buttonlink}
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    >
                      {item.buttontext}
                    </Link>
                  </div>
                </div>
                <div className="mt-4 text-sm">
                  <a href={item.bottomlink} className="font-medium text-gray-900" target='_blank'>
                    {item.bottomlinktext} <span className="font-normal text-gray-500">{item.bottomlinkspan}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

			))}

    </div>
  )
}
